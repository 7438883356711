@tailwind base;
@tailwind components;
@tailwind utilities;

#__next {
    min-height: 100%;
}

.pipe-separated > * + *::before {
    content: ' | ';
    color: #222;
}
